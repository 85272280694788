<template>
  <div
    v-if="sizeGuideUrl"
    :class="className">
    <i :class="iconClass" />
    <a
      href="#"
      :aria-label="linkLabel"
      @click.prevent="handleSizeGuideModal">
      {{ linkLabel }}
    </a>
  </div>
</template>

<script>
import { inject, computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { mapGetter, mapAction } from 'CommonUtils/store/state.js';
import UtilsURL from 'CommonUtils/url.js';
import UtilsAnalytics from 'CommonUtils/analytics';

  export default {
    props: {
      label: {
        type: String,
        required: false,
        default: () => '',
      },
      className: {
        type: String,
        required: false,
        default: () => '',
      },
      productLayoutOptions: {
        type: Object,
        default: () => undefined,
      },
    },
    setup(props) {
      const { label } = toRefs(props);
      const { t } = useI18n();
      const sizeGuideUrl = inject('sizeGuideUrl');
      const getCurrentSize = inject('getCurrentSize', () => {
        return {};
      });
      const getDefaultSizeDescription = inject('getDefaultSizeDescription', '');

      const layoutOptions = mapGetter('page/getLayoutOptions');
      const isSizeGuideModalOpen = mapGetter('uiState/getStatusModalSizeGuide');
      const showSizeGuideModal = mapAction('uiState', 'showModal');
      const showSizeSelectorModal = mapAction('selectorState', 'showModal');

      const customizeLayoutOptions = computed(() => {
        if (props.productLayoutOptions) return props.productLayoutOptions;
        return layoutOptions.value;
      });

      const getSizeGuideWithParams = () => {
        if (hasCustomSizeGuide) {
          const currentSizeDescription = getCurrentSize()?.description ?? getDefaultSizeDescription();
          const slugifiedSizeDescription = UtilsURL.slugify(currentSizeDescription ?? props.defaultSizeDescription);
          const urlWithSizeDescriptionParam = UtilsURL.addNewParamToQuery(sizeGuideUrl, {
            tllj: slugifiedSizeDescription,
          });
          return urlWithSizeDescriptionParam;
        }
        return sizeGuideUrl;
      };

      const handleSizeGuideModal = () => {
        const sizeGuideUrlWithParams = getSizeGuideWithParams();
        showSizeGuideModal({
          name: 'modalSizeGuide',
          status: { isOpen: !isSizeGuideModalOpen.value, sizeGuideUrl: sizeGuideUrlWithParams },
        });
        UtilsAnalytics.sendShowSayfitEvent('open_sayfit');
        showSizeSelectorModal({ isOpen: false });
      };

      const { use_size_guide } = customizeLayoutOptions?.value ?? {};
      const hasCustomSizeGuide =
        use_size_guide &&
        use_size_guide in customizeLayoutOptions.value &&
        customizeLayoutOptions.value[use_size_guide]?.url === sizeGuideUrl;

      const classModifier = hasCustomSizeGuide ? use_size_guide : 'api';
      const iconClass = `i-size-guide i-size-guide__${classModifier}`;

      const linkLabel = computed(() => {
        if (label.value) return label;
        if (hasCustomSizeGuide) return t(`product_detail.size_guides.${use_size_guide}`);
        return t('product_detail.size_guide');
      });

      onMounted(() => {
        if(hasCustomSizeGuide) {      
          UtilsAnalytics.sendShowSayfitEvent('show_sayfit');          
        }
      });

      return {
        isSizeGuideModalOpen,
        handleSizeGuideModal,
        sizeGuideUrl,
        iconClass,
        linkLabel,
      };
    },
  };
</script>
